import React from 'react';
import ADS_TEST_IDS from 'constants/testsIds/ads';
import { InStreamAd, AdsCenteredDiv } from './Styles';
import AdSlotToggle from './AdSlotToggle';

interface HomePageInStreamProps {
  id: number;
}

const HomePageInStream: React.FC<HomePageInStreamProps> = ({ id }: HomePageInStreamProps) => (
  <AdsCenteredDiv>
    <InStreamAd
      data-cy={`${ADS_TEST_IDS.HP_IN_STREAM}-${id}`}
      className='homepage'
    >
      <AdSlotToggle
        placementName='Homepage-InStream'
        index={id + 1}
        dataCy={`${ADS_TEST_IDS.HP_IN_STREAM}-${id}-child`}
        slotId={`InStream${id}`}
      />
    </InStreamAd>
  </AdsCenteredDiv>
);

export default HomePageInStream;
